import { createApp } from 'vue'
import App from './App.vue'

// 引入路由组件
import router from "./router/index.js"
// 引入仓库vuex
import store from "./store/index.js";

// 引入字体图标库
import './assets/font/iconfont.css'

// 引入element ui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 引入全局组件
import FixedArea from './components/FixedArea.vue'


// 引入axios
import axios from 'axios'
import VueAxios from 'vue-axios'
// 引入mock
//import "./mock/index.js"

// 引入全局样式
import './assets/css/Normalize.css'

const app = createApp(App)
app.component('FixedArea', FixedArea)
app.use(ElementPlus, { locale: zhCn })
app.use(VueAxios, axios).use(store).use(router).mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

//router.beforeEach((to, from, next) => {
   // console.log(to)

//if(to.meta.content){
//    let head = document.getElementsByTagName('head');
//    let meta = document.createElement('meta');
//    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
//    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
//    meta.content = to.meta.content;
//    head[0].appendChild(meta)
//}
//// /* 路由发生变化修改页面title */
//if (to.meta.title) {
//    document.title = to.meta.title;
//}
//next()
//});



