// 引入api
import { reqSupport, reqServiceList } from "@/api";

const contactUs = {
    namespaced: true,
    state: {
        supportList: {},  // 售前售后人员列表
        serviceList: [],  //服务列表
    },
    actions: {
        getSupport(context) {
            context.commit('GETSUPPORT')
        },
        getServiceList(context) {
            context.commit('GETSERVICELIST')
        }
    },
    mutations: {
        // 支持列表
        async GETSUPPORT(state) {
            const { data: res } = await reqSupport()
            if (res.code === 1) {
                state.supportList = res.data
            }
        },
        // 服务列表
        async GETSERVICELIST(state) {
            const { data: res } = await reqServiceList()
            if (res.code === 1) {
                state.serviceList = res.data
            }
        }
    },
    getters: {}
}

export default contactUs