import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";

const routes = [
    {
        name: 'layout',
        path: '/',
        component: () => import('@/Layout/layout.vue'),
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@/pages/index.vue'),
                meta: { title: '' }
            }, {
                path: '/product',
                name: 'productCenter',
                component: () => import('@/pages/ProductCenter/ProductCenter.vue'),
                meta: { title: '产品中心' },
                children: [
                    {
                        path: 'B2bB2cSell',
                        name: 'B2bB2cSell',
                        component: () => import('@/pages/ProductCenter/B2bB2cSell.vue')
                    }, {
                        path: 'duomendian',
                        name: 'duomendian',
                        component: () => import('@/pages/ProductCenter/DuoMenDian.vue')
                    }, {
                        path: 'applets',
                        name: 'applets',
                        component: () => import('@/pages/ProductCenter/AppLets.vue')
                    }, {
                        path: 'apps',
                        name: 'apps',
                        component: () => import('@/pages/ProductCenter/AppPages.vue')
                    }, {
                        path: 'moveManage',
                        name: 'moveManage',
                        component: () => import('@/pages/ProductCenter/MoveManage.vue')
                    }, {
                        path: 'B2B2C_md',
                        name: 'B2B2C_md',
                        component: () => import('@/pages/ProductCenter/B2B2C_md.vue')
                    }, {
                        path: 'B2B2C',
                        name: 'B2B2C',
                        component: () => import('@/pages/ProductCenter/B2B2C.vue')
                    }, {
                        path: 'channel',
                        name: 'channel',
                        component: () => import('@/pages/ProductCenter/ChannelPage.vue')
                    }, {
                        path: 'B2B2B2CP',
                        name: 'B2B2B2CP',
                        component: () => import('@/pages/ProductCenter/B2B2B2CP.vue')
                    }, {
                        path: 'distrib',
                        name: 'distrib',
                        component: () => import('@/pages/ProductCenter/DistribPage.vue')
                    }, {
                        path: 'seckill',
                        name: 'seckill',
                        component: () => import('@/pages/ProductCenter/SeckillPage.vue')
                    }, {
                        path: 'decoration',
                        name: 'decoration',
                        component: () => import('@/pages/ProductCenter/DecorationPage.vue')
                    }
                ]
            }, {
                path: '/case',
                name: 'customerCase',
                component: () => import('@/pages/CustomerCase/CustomerCase.vue'),
                meta: { title: '客户案例' }
            }, {
                path: '/contact',
                name: 'contactUs',
                component: () => import('@/pages/ContactUs/ContactUs.vue'),
                meta: { title: '联系我们' }
            }, {
                path: '/news',
                name: 'news',
                redirect: '/news/newsInfo',
                component: () => import('@/pages/NewsPage/NewsPage.vue'),
                meta: { title: '新闻资讯' },
                children: [
                    {
                        path: 'newsInfo',
                        name: 'newsInfo',
                        component: () => import('@/pages/NewsPage/NewsInfo.vue')
                    }, {
                        path: 'newsDetail',
                        name: 'newsDetail',
                        component: () => import('@/pages/NewsPage/NewsDetail.vue')
                    }
                ]
            }, {
                path: '/about',
                name: 'about',
                component: () => import('@/pages/AboutUs/AboutUs.vue'),
                meta: { title: '关于我们' },
            }
        ]
    },
    {
        name: 'home',
        path: '/'
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    // 路由跳转回到顶部
    scrollBehavior() {
        return { left: 0, top: 0 }
    }
})

export default router