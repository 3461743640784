// vuex 仓库
import { createStore } from 'vuex'
// 引入模块化的仓库
import home from './home.js'
import customerCase from './case.js'
import contactUs from "./contactUs";
import newsPage from "./news.js"
import aboutUs from "./aboutUs.js"


const store = createStore({
    state: {

    },
    actions: {},
    mutations: {},
    getters: {},
    modules: {
        home,
        customerCase,
        contactUs,
        newsPage,
        aboutUs
    }
})

export default store