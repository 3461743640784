// 引入api
import { reqAboutBanner, reqAboutUs } from "@/api";
import { ElMessage } from 'element-plus'

const aboutUs = {
    namespaced: true,
    state: {
        pageInfo: {}
    },
    actions: {

    },
    mutations: {
        async GETABOUTUS(state) {
            const { data: res } = await reqAboutUs()
           // console.log(res, '@#@');
            if (res.code == 1) {
                state.pageInfo = res.data
            } else {
                ElMessage.error(res.msg || '请求错误')
            }
        }
    },
}

export default aboutUs