// axios配置
// 引入域名相关资料
import { apiUrl } from "@/config/config";
// 引入进度条
import nProgress from "nprogress";
import '../../node_modules/nprogress/nprogress.css';

// 导入axios
import axios from 'axios'

// 创建新的axios实例
const request = axios.create({
    // 设置公共接口
    baseURL: apiUrl.INDEX_URL,
    // 设置超时时间
    timeout: 3 * 1000
})

// 2.配置请求拦截器
request.interceptors.request.use(config => {
    // 数据转换成json格式
    // config.data = JSON.stringify(config.data);
    // config.headers = { 配置请求头 }
    // 开启进度条
    nProgress.start()
    config.headers['Byshun-Device-Type'] = 'web'

    return config
})

// 3.配置响应拦截器
request.interceptors.response.use(response => {
    // 关闭进度条
    nProgress.done()
    return response
}, error => {
    // 响应失败的回调
    return Promise.resolve(error.response)
})

// 暴露文件
export default request