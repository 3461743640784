<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {

  },setup(){

  },

  watch: {
      '$route':'getPath',
      'seo_setting':'setMeta'
    },
    methods: {
        is_pc:function(){
            var userAgentInfo = navigator.userAgent;
                    var Agents = ["Android", "iPhone",
                        "SymbianOS", "Windows Phone",
                        "iPad", "iPod"];
                    var flag = true;
                    for (var v = 0; v < Agents.length; v++) {
                        if (userAgentInfo.indexOf(Agents[v]) > 0) {
                            flag = false;
                            break;
                        }
                    }
                    return flag;
        },
      setMeta:function(){
        let curr_path = this.$route.path;
       
        let keys = "";
          switch (curr_path){
              case "/" :keys = "index";break;
              case "/case":keys = "cases";break;
              case "/contact":keys = "contact_us";break;
              case "/news/newsInfo":keys = "news";break;
              case "/news/newsDetail":keys = "news_detail";break;
              case "/about" :keys = "about_us";break;
          }
          if(keys && this.seo_setting[keys]){
            document.title = this.seo_setting[keys].title;
            document.querySelector('meta[name="keywords"]').setAttribute('content', this.seo_setting[keys].keywords)
            document.querySelector('meta[name="description"]').setAttribute('content', this.seo_setting[keys].description)
          }
      },
      getPath(n,o){
        if(this.seo_setting.index){
          this.setMeta();
        }      
      }
    },
    mounted() {

         this.$store.commit("home/GETSEO");
    },
    computed: {
       seo_setting() {
           return this.$store.state.home.seo_setting || {};
       },
    },
    created:function(){
        let is_pc =  this.is_pc();
        if(!is_pc){
             window.location.href='https://m.byshun.cn/'
        }
    }

}
</script>

<style>

</style>
