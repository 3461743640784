// 整合所有请求

// 引入封装好的axios
import request from "./axios";

// 分别暴露请求

// 请求网站基本数据
export const reqBaseData = () => request({ method: 'get', url: '/api/Home/getSetting' })
export const reqHomeData = () => request({ method: 'get', url: '/api/Home/getHomeData' })


export const reqSeoSetting = () => request({ method: 'get', url: '/api/Home/getSeoSetting' })

// 首页相关请求
// 1、获取banner图
export const reqBanner = () => request({ method: 'get', url: '/api/home/swiperdata' })
// 2、获取banner图下的产品信息
export const reqBusiness = () => request({ method: 'get', url: '/api/home/business' })
// 3、获取产品介绍信息
export const reqProducts = () => request({ method: 'get', url: '/api/home/products' })
// 4、请求案例信息
export const reqCase = () => request({ method: 'get', url: '/api/home/case' })
// 5、请求新闻信息
export const reqNews = () => request({ method: 'get', url: '/api/home/news' })
// 4、请求案例信息
export const reqCate = () => request({ method: 'get', url: '/api/Case/getCateList' })

// 案例页相关请求
// 1、获取案例分类
export const reqCaseCate = () => request({ method: 'post', url: 'api/Case/getCateList' })
// 2、获取该分类下的列表数据
export const reqCaseList = (val) => request({ method: 'post', url: 'api/Case/getCaseList', data: { 'cate_id': val } })


/**
 *  @describe 新闻页面 
*/
// 1、获取最新/最热文章
export const reqHotNews = () => request({ method: 'post', url: 'api/Article/getNewsHot' })
// 2、获取新闻列表数据
export const reqNewsInfo = (val) => request({ method: 'post', url: 'api/Article/get_all_list', data: val })
// 3、获取新闻数据
export const reqNewsDetail = (val) => request({ method: 'post', url: 'api/Article/detail', data: { id: val } })


/**
 * @describe 关于我们页面
 */
// 关于我们数据接口
export const reqAboutUs = () => request({ method: 'post', url: 'api/About/about_us' })


/**
 * @describe 联系我们页面 
 */
// 联系我们页面请求
// 1、售前售后
export const reqSupport = () => request({ method: 'get', url: 'api/contact/info' })
// 2、服务列表
export const reqServiceList = () => request({ method: 'get', url: 'api/contact/serve' })


