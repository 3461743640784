
// 项目配置文件
const apiUrl = {
    // 后端接口域名
    // INDEX_URL: 'https://www.uinav.com'
    INDEX_URL: 'https://m.byshun.cn/'
}

module.exports = {
    // 域名接口
    apiUrl: apiUrl,
    // 页签标题
    title: "舜津科技",
    //开发环境端口号
    devPort: "9876",
    //公司名称
    companyName: "成都舜津科技有限公司",
    // 备案号
    icCase: "蜀ICP备16032742号",
    companyAddress: "成都市龙泉驿区杜鹃街62号附203-205",
    // 系统介绍
    systemDesc:
        "为传统企业量身打造的全渠道产业互联网线上线下一体化新零售营销系统！",
    //加载时显示文字
    loadingText: "正在加载中...",
    //是否显示顶部进度条
    showProgressBar: true,
    // 百度地图AK
    baiduMapKey: '0iMxsUa0Zf5GOYhUQneLsmxhOzUZmp0S',

};