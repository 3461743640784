<template>
    <div class="fixed-box">
        <div class="content">
            <img src="@/assets/icons/fixed_head_tx.png" class="header-img" alt="" />
            <!-- phone -->
            <div class="tel-box">
                <div class="line1">
                    <div class="line1-title">
                        <img src="@/assets/icons/icon_tel.png" alt="" />
                        <span>售前客服热线</span>
                    </div>
                    <p style="white-space:nowrap">{{baseData.customer_phone}}</p>
                </div>
            </div>
            <!-- QQ -->
            <a class="QQ-contact" target="_blank" :href="baseData.customer_qq_link">
                <img src="@/assets/icons/fixed_head_qq.png" alt="" />
                <p>QQ咨询</p>
                <p style="white-space:nowrap;font-weight:700;color:red">{{baseData.customer_qq}}</p>

            </a>
            <!-- wechat -->
            <div class="wechat-box">
                <img :src="baseData.customer_qr" alt="" />
                <p>添加客服微信</p>
            </div>
            <!-- other way -->
            <ul class="other-box">
                <li class="box-item">
                    <router-link target="_blank" to="/contact">联系我们</router-link>
                </li>
                <li class="box-item">
                    <router-link target="_blank" to="/about">关于我们</router-link>
                </li>
                <li class="box-item">
                    <router-link target="_blank" to="/">帮助中心</router-link>
                </li>
                <li class="box-item">
                    <router-link target="_blank" to="/case">服务介绍</router-link>
                </li>
            </ul>
        </div>
        <div class="back-top">
            <div class="line"></div>
            <div class="top-box" @click="backTop()">
                <el-icon size="30" color="#ffffff">
                    <ArrowUp />
                </el-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FixedArea",
    setup() {
        const backTop = () => {
            // 回到顶部
            let scrollY = document.documentElement.scrollTop;
            // 利用定时器，视觉造成过度动画
            let timer = setInterval(() => {
                scrollY -= 80;
                if (scrollY <= 0) {
                    scrollY = 0;
                    clearInterval(timer);
                    console.log("删除了定时器");
                }
                document.documentElement.scrollTop = scrollY;
            }, 8);
        };
        return {
            backTop,
        };
    },
    computed: {
        // 从仓库获取数据
        baseData() {
            return this.$store.state.home.base_data;
        },
    }
};
</script>

<style scoped lang="scss">
.fixed-box {
    z-index: 10;
    position: fixed;
    right: 20px;
    top: 150px;
    width: 135px;
    height: 520px;
    padding: 60px 10px 0 10px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #eee;
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .header-img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            transform: translateY(-50%);
        }
        .tel-box {
            font-size: 12px;
            color: #808080;
            border-bottom: 1px solid #e9e7e7;

            .line1 {
                width: 100%;
                .line1-title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;
                    img {
                        margin-right: 5px;
                    }
                }

                p {
                    color: red;
                    font-weight: 700;
                    font-size: 15px;
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                }
            }
        }
        .QQ-contact {
            cursor: pointer;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #e9e7e7;

            p {
                text-align: center;
                font-size: 14px;
                color: #333;
            }
        }
        .wechat-box {
            width: 100%;
            padding: 15px 0;
            border-bottom: 1px solid #e9e7e7;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 70px;
                height: 70px;
            }
            p {
                text-align: center;
                font-size: 14px;
                color: #333;
            }
        }
        .other-box {
            width: 100%;
            padding-top: 15px;

            .box-item {
                width: 100%;
                text-align: center;
                padding: 6px 0;
                margin: 3px 0;
                background-color: #f5f5f5;
                border-radius: 3px;
                &:hover {
                    background-color: #1ec981;
                    a {
                        color: #ffffff;
                    }
                }
            }
        }
    }
    .back-top {
        .line {
            width: 1px;
            height: 25px;
            background-color: #1ec981;
            margin: 0 auto;
            margin-top: 15px;
        }
        .top-box {
            width: 40px;
            height: 40px;
            background-color: #1ec981;
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 0.3rem;
        }
    }
}
</style>
