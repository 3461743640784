import { reqNewsInfo, reqHotNews, reqNewsDetail } from "@/api"
import { ElMessage } from 'element-plus'
import router from "@/router"

const news = {
    namespaced: true,

    state: {
        siderNews: {}, // 最新、最热文章
        newsInfo: {}, // 新闻列表数据
        newsDetail: {}, // 新闻详情
        footerInfo: {}, // 上一篇、下一篇
        newDetailId: sessionStorage.getItem('newsDetailId') || '', // 新闻详情的id
        headerBarActive: '', // 暂存的header-active

    },
    actions: {

    },
    mutations: {
        // 获取最热文章
        async GETHOTNEWS(state) {
            const { data: res } = await reqHotNews()
            if (res.code === 1) {
                state.siderNews = res.data
            } else {
                ElMessage.error(res.msg || '请求错误')
            }
        },
        // 获取新闻列表数据
        async GETNEWSINFO(state, val) {
            const { data: res } = await reqNewsInfo(val)
            if (res.code === 1) {
                state.newsInfo = res.data
            } else {
                ElMessage.error(res.msg || '请求错误')
            }
        },
        // 获取新闻数据
        async GETNEWSDETAIL(state) {
            console.log('获取新闻详情');
            const { data: res } = await reqNewsDetail(state.newDetailId)
            if (res.code === 1) {
                state.newsDetail = res.data // 新闻数据               
            } else {
                ElMessage.error(res.msg || '请求错误')
            }
        },
        // 存储新闻详情di
        STORAGEID(state, val) {
            state.newDetailId = val
            sessionStorage.setItem('newsDetailId', val)
        },
        // 更改headerBar样式
        CHANGHEADERBAR(state, val) {
            console.log(345, val);

            state.headerBarActive = val
            console.log(state.headerBarActive);
        },
        // 清空headerBar样式
        REMOVEHEADERBAR(state) {
            state.headerBarActive = null
        }
    }
}

export default news