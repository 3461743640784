// 首页相关的仓库
import { reqBanner, reqBusiness, reqProducts, reqCase, reqNews, reqBaseData ,reqSeoSetting} from '@/api/index'

const home = {
    namespaced: true,
    state: {
        swiperList: [], // 轮播图数据
        business: [], // 轮播图下方产品信息
        products: [], // 产品介绍区域
        case: [], // 案例信息
        news: [], // 新闻数据
        base_data: {}, // 基本数据footer/fixarea
        seo_setting:{}
    },
    actions: {
        // 获取轮播图数据
        getSwiper(context) {
            context.commit('GETSWIPER')
        },
        // 获取轮播图下产品信息数据
        getBusiness(context) {
            context.commit('GETBUSINESS')
        },
        // 获取产品广告信息
        getProducts(context) {
            context.commit('GETPRODUCTS')
        },
        // 获取案例信息
        getCase(context) {
            context.commit('GETCASE')
        },
        // 获取新闻列表
        getNews(context) {
            context.commit('GETNEWS')
        }
    },
    mutations: {
        async GETSWIPER(state) {
            const { data: result } = await reqBanner()
            if (result.code == 200) {
                state.swiperList = result.data
            }
        },
        async GETBUSINESS(state) {
            const { data: result } = await reqBusiness()
            if (result.code == 200) {
                state.business = result.data
            }
        },
        async GETPRODUCTS(state) {
            const { data: result } = await reqProducts()
            if (result.code == 200) {
                state.products = result.data
            }
        },
        async GETCASE(state) {
            const { data: result } = await reqCase()
            if (result.code == 200) {
                state.case = result.data
            }
        },
        async GETNEWS(state) {
            const { data: result } = await reqNews()
            if (result.code == 200) {
                state.news = result.data
            }
        },
        // 获取layout数据
        async GETBASEDATE(state) {
            const { data: res } = await reqBaseData()
            if (res.code == 1) {
                state.base_data = res.data
            } else {
                console.log('请求失败');
            }
        },
        async GETSEO(state) {
            const { data: result } = await reqSeoSetting();
            if (result.code == 1) {
                state.seo_setting = result.data
            }
        }
    },
    getters: {},
}

export default home