// 引入api
import { reqCaseList, reqCaseCate } from "@/api";
import { ElMessage } from 'element-plus'

const customerCase = {
    namespaced: true,
    state: {
        caseCate: [], // 案例分类
        caseList: [], // 案例数据
    },
    actions: {

    },
    mutations: {
        // 1、获取案例分类
        async GETCASECATE(state) {
            const { data: res } = await reqCaseCate()
            if (res.code === 1) {
                state.caseCate = res.data
            } else {
                ElMessage.error(res.msg || '请求失败');
            }
        },
        async GETCASELIST(state, val) {
            const { data: res } = await reqCaseList(val)
            if (res.code == 1) {
                state.caseList = res.data
            } else {
                ElMessage.error(res.msg)
            }
        }
    },
}

export default customerCase